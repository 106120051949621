@import '~normalize.css';
@import '~antd/dist/antd.min.css';

:root {
  /* Global CSS variables */
  --button-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --primary-font: 'Sailec';
  --text-default-dize: 14px;

  /* Primary palette */
  --solid-white: #ffffff;
  --alabaster-light: #fafafa;
  --alabaster: #f3f3f3;
  --cadet-blue: #b0b6c1;
  --waterloo: #7c859d;
  --san-juan: #344775;

  /* Secondary palette */
  --dodger-blue: #23a2f5;
  --wasabi: #77c76f;
  --carnation: #ff585b;
  --neon-carrot: #fe9338;
  --bright-sun: #ffc944;
  --moody-purple: #8c7eca;
  --hot-pink: #ff71a8;
  --streetlight-green: #52c41b;

  /* Box-shadow */
  --black: #000000;
  --drop-shadow: #dcdfe2;

  --admin-blue: '#4a90e2';
  --dark-blue: '#233350';

  /* z-index */
  --z-index-dropdown: 1000;
  --z-index-fixed: 1030;
  --z-index-sticky: 1030;
  --zindex-modal: 1040;
  --zindex-popover: 1050;
  --zindex-tooltip: 1060;
}

/* Sailec Regular - 400 */
@font-face {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  src: local('Sailec Regular'), local('Sailec-Regular'), url(../../bvdash-core/fonts/sailec.woff) format('woff');
}
/* Sailec Medium - 500 */
@font-face {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 500;
  src: local('Sailec Medium'), local('Sailec-Medium'), url(../../bvdash-core/fonts/sailec-medium.woff) format('woff');
}
/* Sailec Black - 700 */
@font-face {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  src: local('Sailec Black'), local('Sailec-Black'), url(../../bvdash-core/fonts/sailec-black.woff) format('woff');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background-color: #f9f9f9;
}
ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: var(--text-default-dize);
  color: var(--san-juan);
  background-color: var(--alabaster);
  box-sizing: border-box;
  font-family: var(--primary-font);
}
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  font-family: inherit;
}
*::before,
*::after {
  box-sizing: inherit;
}

.text {
  font-size: var(--text-default-dize);
  color: var(--san-juan);
}

@keyframes shake {
  8%,
  41% {
    transform: translateX(-7px);
  }
  25%,
  58% {
    transform: translateX(7px);
  }
  75% {
    transform: translateX(-3px);
  }
  92% {
    transform: translateX(3px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 1000;
  border-radius: 5px;
}
[data-reach-dialog-content] {
  width: 500px;
  max-width: 95%;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
  border-radius: 5px;
}

.BVDASH_TABLE_FILTER .ant-popover-inner,
.BVDASH_TABLE_ACTION_BUTTON .ant-dropdown-menu {
  max-width: 230px;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(49, 61, 90, 0.2), inset 0 -1px 0 0 var(--alabaster);
}

.ant-dropdown {
  z-index: 10000000 !important;
}

.DraggableSliderPortal {
  position: absolute;
}

.ant-picker-dropdown {
  z-index: 1050000 !important;
}

button.ant-btn {
  border-radius: 16px;
}

div.ant-modal-content,
input.ant-input,
input.ant-input-number-input,
textarea.ant-input,
div.ant-popover,
div.ant-popover-inner-content,
div.ant-popover-content,
div.ant-input-number,
div.ant-popover-inner,
div.ant-picker {
  border-radius: 8px;
  /*z-index: 100000 !important;*/
}

.ant-tag {
  border-radius: 16px !important;
}

div.ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}

div.ant-modal-footer {
  border-radius: 0 0 8px 8px !important;
}

div.ant-select-selector {
  border-radius: 8px !important;
}

.ManageTableSliderTooltip,
.ant-select-dropdown,
.ExistingLaborFormTooltip {
  /*@TODO remove before deployment*/
  z-index: 10000000000000 !important;
}

.loading-cursor {
  cursor: progress;
}

.ant-form-item-label {
  white-space: pre-wrap !important;
}
